<script setup>
    const props = defineProps(["filterItem","filterObject","inputWidth",'dropdown']);

    const width = (props.inputWidth??180) + 'px';
    const id = 'dropdownOperators_' + new Date();

   // const defaultOperator = props.filterItem.defaultOperator;
    //const operatorsList = props.filterItem.limitedOperators;
    const vType = props.filterItem.valueType;
    let addOrEqual = false;

    //orequal

    const label = {
        first:"beginswith",
        second:"endswith"
    }

    if(vType === "number"){
        label.first = "greaterthan";
        label.second = "lessthan";
        addOrEqual = true;
    }

    if(props.filterItem.isDate){
        label.first = "dategreaterthan";
        label.second = "datelessthan";
        addOrEqual = true;
    }
    
    const doSearchEnter = (evt)=>{
        props.filterObject.apply();
        if(props.dropdown)
        props.dropdown.close();
    }
    const onEndInput = () =>{
        if(props.filterItem.valueType != "string" && props.filterItem.combinedStartValue){
            props.filterItem.operator = "between";
            props.filterItem.selectedValue = [props.filterItem.combinedStartValue,props.filterItem.combinedEndValue];
           
            
        }else{
            props.filterItem.operator = label.second+(addOrEqual?"orequal":"");
            props.filterItem.combinedStartValue = null;
            setSelectedValue(props.filterItem.combinedEndValue);

        }
       
    }

    const onInput = ()=>{
        if(props.filterItem.valueType != "string" && props.filterItem.combinedEndValue){
            props.filterItem.operator = "between";
            props.filterItem.selectedValue = [props.filterItem.combinedStartValue,props.filterItem.combinedEndValue];
           
            
        }else{

            props.filterItem.operator = label.first+(addOrEqual?"orequal":"");
            props.filterItem.combinedEndValue = null;
            setSelectedValue(props.filterItem.combinedStartValue);
       
        }

    }

    const setSelectedValue = (pValue) =>{
        
        if(props.filterItem.limitedValue) props.filterItem.limitedValue = null;
       
        if(props.filterItem.selectedValue !== null && !pValue){
            props.filterItem.selectedValue = null;
            doSearchEnter();
        }else{
            props.filterItem.selectedValue = pValue;
        }
        
        
    }
  

  

</script>
<template>
    <div class="align-items-center d-flex mb-1">
        <label :style="{'width':width}">{{filterItem.getOperatorTitle(label.first)}}</label>
        
        <input v-if="!filterItem.isDate" type="search" class="form-control form-control-sm rounded-0" 
            v-model = "filterItem.combinedStartValue" @input = "onInput" @keyup.enter="doSearchEnter">

        <ODatePicker 
            v-if="filterItem.isDate"
            v-model="filterItem.combinedStartValue" 
            class="dp-override"
            :inputClassName="'form-control form-control-sm rounded-0'"
            format = "Short Date"
            :timePicker="false"
             :callback="onInput"
        ></ODatePicker>
    </div>
    <div class="align-items-center d-flex mb-1">
        <label :style="{'width':width}">{{filterItem.getOperatorTitle(label.second)}}</label>
        
        <input v-if="!filterItem.isDate" type="search" class="form-control form-control-sm rounded-0" 
            v-model = "filterItem.combinedEndValue" @input = "onEndInput" @keyup.enter="doSearchEnter">

        <ODatePicker 
            v-if="filterItem.isDate"
            v-model="filterItem.combinedEndValue" 
            class="dp-override"
            :callback="onEndInput"
            :inputClassName="'form-control form-control-sm rounded-0'"
            format = "Short Date"
            :timePicker="false"
        ></ODatePicker>
    </div>
   
 
    
</template>